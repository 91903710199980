.crm-iframe-popup {
    background: #eef2f4 !important;
    padding: 0 15px 21px 21px;
    box-sizing: border-box;
}

.react-datepicker {
    width: 100%;
    border: 1px solid #c4c7cc;
    border-radius: 1px;
}

.react-datepicker-popper {
    z-index: 999;
}

.react-datepicker__month-container {
    width: calc(100% - 71px);
}

.react-datepicker__header,
.react-datepicker__time-container {
    border: none;
    border-radius: 0;
}

.react-datepicker__time-list {
    padding: 0;
}

.crm-entity-section.crm-entity-section-control {
    padding: 10px 0 10px 0;
}

.crm-entity-section-control-error-block {
    color: red;
    font-weight: bold;
    padding-bottom: 10px;
}

.crm-entity-widget-block-error .crm-entity-widget-content-block-inner,
.crm-entity-widget-block-error .crm-entity-widget-content-block-clients,
.crm-entity-widget-block-error .main-ui-control {
    border-color: #ff5752 !important;
}

.crm-entity-card-container-content,
.crm-entity-card-container {
    margin-bottom: 70px;
}

.react-select__control {
    border-radius: 1px !important;
}

.react-select__control--isFocused {
    border-radius: 1px !important;
    border-width: 1px !important;
    box-shadow: none !important;
}

.crm-entity-card-container {
    counter-reset: widgets;
}

.crm-entity-card-widget {
    counter-increment: widgets;
    z-index: calc(150 - var(--widgets));
}

.loading {
    opacity: 0.25;
    display: inline-block;
}

button .loading {
    opacity: 1;
}

.loading_wrap {
    opacity: 0.25;
    display: block;
    padding: 20px;
    width: 100%;
    text-align: center;
}

.crm-entity-stream-section .crm-entity-widget-content-block-title {
    padding: 5px 35px;
}

.ui-btn[disabled] {
    opacity: 0.15;
}

.ui-btn.ui-btn-xs {
    min-width: 16px;
}

.crm-entity-stream-content-event {
    min-height: 16px;
    padding: 8px 15px 10px 15px;
}

.crm-entity-stream-section-planned .crm-entity-stream-content-event {
    padding-left: 15px;
}

.crm-entity-stream-content-new-detail.focus {
    padding-bottom: 10px;
}

.crm-entity-stream-content-new-detail.focus .crm-entity-stream-content-new-comment-btn-container {
    position: relative;
    height: auto;
}

.crm-entity-stream-content-new-detail.focus
    .crm-entity-stream-content-new-comment-btn-container
    .ui-btn.ui-btn-xs {
    margin-bottom: 10px;
    margin-left: 0px !important;
    margin-right: 10px;
}

.crm-entity-stream-section-additional::before {
    top: inherit;
    bottom: 0;
}

.crm-entity-stream-section-comment-editor textarea {
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    height: 100px;
}

.crm-entity-stream-section-comment-editor textarea:focus {
    outline: none;
}

.main-ui-square {
    z-index: inherit;
}

.crm-entity-stream-content-detail {
    width: 100%;
}

.crm-entity-stream-content-detail .rich-editor {
    font-size: inherit;
    box-shadow: none;
    border-bottom: none;
}

.merchandise-image {
    cursor: pointer;
    position: absolute;
    right: 50px;
}

.merchandise-image img {
    width: auto;
    height: 40px;
}

.mobile {
    margin: 0 15px 20px 15px;
}

.mobile .crm-entity-card-container,
.mobile .crm-entity-card-container-shadow,
.mobile .crm-entity-stream-container,
.mobile .crm-entity-stream-container-shadow {
    float: none;
    width: 100%;
}

.mobile .back-button {
    color: #ccc;
    cursor: pointer;
    position: absolute;
    right: 0;
}

.mobile .crm-entity-section.crm-entity-section-control {
    position: relative;
    margin-bottom: 30px;
}

.mobile .crm-entity-stream-section-content-new-header {
    height: auto;
}

.mobile .crm-entity-widget-content-input-datetime {
    width: 100%;
    padding: 10px 9px;
    box-sizing: border-box;
    background-color: #fff;
    color: #424956;
    font: 15px/17px 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.weekCalendar__headerColumn,
.weekCalendar__scaleHeader {
    padding-top: 4px;
    color: #a3a9b1 !important;
}

.weekCalendar__scaleHeader,
.weekCalendar__header {
    color: #a3a9b1 !important;
    font: 12px/14px 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
